/* Reset some default styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root, body, html {
    margin: 0;
    font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif; /* Example using Roboto, ensure it's included in your project */
    background-color: #f5f5f5; /* Soft gray background */
    color: #e5e5e5; /* Dark gray text for readability */
    font-size: 16px; /* Base font size */
    line-height: 1.6; /* Improve text readability */
    height: 100%; /*Ensure full page height */
    width: 100%;
    overflow: hidden;
}

.MainSpace {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible
}

.Apply {
    position: relative; /* or 'fixed' if you want it to cover the viewport */
    width: 100%;
    height: 100%;
    /* overflow: visible; */
    background-color: transparent;
  }

.form-container {
    position: static;
    transform: translate(-0%, -100%); /* Center the form container */
    z-index: 2; /* Ensure it's above the canvas */
    height: 95vh;
    width: 60vw; 
    padding: 20px 40px;
    background-color: rgba(255, 255, 255, 0);
    max-width: 80vw; /* Maximum width of the form */
    min-width: 45vw; 
    margin: auto auto; /* Center the form on the page */
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;


}

.application-form {
    background-color:  rgba(40, 39, 42, 0.750);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    overflow-y: auto;
    max-height: 85vh;
    z-index: 100;
}

.application-form h2 {
    /* scroll-margin-top: 100px; */
    margin-bottom: 2rem; /* Space below the form title */
    color: #ececec; /* Title color */
    font-weight: 300; /* Lighter font weight for the title */
    z-index: 1;
}

.form-group {
    margin-bottom: 1rem; /* Space between form groups */
}

.form-group label {
    display: block;
    margin-bottom: .5rem; /* Small space between label and input */
    font-weight: 300; /* Emphasize labels */
}

.uploadcare--widget__button {
    background-color: #333;
    color: #e5e5e5!;
    font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif!; /* Example using Roboto, ensure it's included in your project */
    transition: background-color color 0.2s;
    font-size: 1rem;
    font-weight: 300;
}

.uploadcare--widget__button:hover {
    background-color: #fff;
    color: #333;
}


.asterisk {
    font-size: 0.775rem;
    margin-bottom: .5rem;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="date"],
.form-group textarea,
.form-group input[type="file"] {
    width: 100%; /* Full width inputs */
    padding: 10px;
    border:rgba(40, 39, 42, 0.668); /* Subtle border */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: .5rem; /* Space for potential error message */
    background-color: rgba(37, 35, 40, 0.668);
    color: #e5e5e5;
    font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif; /* Example using Roboto, ensure it's included in your project */
}

.form-group textarea {
    height: 120px; /* Larger text area for bio */
    resize: vertical; /* Allow vertical resizing */
    background-color: rgba(37, 35, 40, 0.668);
    color: #e5e5e5;
    font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif; /* Example using Roboto, ensure it's included in your project */
}

/* Styling for radio buttons and conditional text input */
.form-group input[type="radio"] {
    margin-right: 5px; /* Space out radio buttons */
    color: #e5e5e5;
    font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif; /* Example using Roboto, ensure it's included in your project */
}

button[type="submit"] {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif;
}

button[type="submit"]:hover {
    background-color: #444; /* Darker on hover */
}

/* Error message styling */
span {
    color: #d32f2f; /* Red color for errors */
    font-size: 0.875rem; /* Smaller font size for errors */
}

.dim-background {
    background-color: rgba(0, 0, 0, 0.694);
}

.success-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: rgb(0, 204, 0);
    animation: checkmark 1s ease forwards;
    z-index: 100;
}

@keyframes checkmark {
    0% {
        opacity: 0;
        transform: scale(0.5) translate(-50%, -50%);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(1.2) translate(-50%, -50%);
    }
}

/* Ensure the rest of the page is clickable when the success animation is not active */
.success-animation:not(:empty) {
    pointer-events: none;
}

@media (max-width: 768px) {
    .form-container {
        width: 90vw; /* Adjust form width on smaller screens */
        padding: 20px 20px; /* Reduce padding on smaller screens */
        /* transform: none; Adjust or remove transform if needed */
        margin-top: 0vh; /* Add some top margin for spacing */
        margin-bottom: 8vh;
        max-width: 90vw; /* Increase maximum width for smaller screens */
        min-width: 0; /* Remove minimum width constraint */
    }

    .application-form {
        max-height: 85vh; /* Remove max-height to allow form to take necessary space */
        padding: 1.5rem; /* Reduce padding */
    }

    .application-form h2 {
        font-size: 1.5rem; /* Adjust title font size for smaller screens */
    }

    .form-group label,
    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="date"],
    .form-group textarea,
    .form-group input[type="file"],
    button[type="submit"] {
        font-size: 0.9rem; /* Adjust font size for better readability on smaller screens */
    }

    .form-group textarea {
        height: 100px; /* Adjust textarea height */
    }
}