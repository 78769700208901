html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background-color: rgb(127.5, 127.5, 127.5);
  
}

.MainSpace {
  background-color: rgb(127.5, 127.5, 127.5);
  height: auto;
  width: 100%;
  flex: 1 1 auto;
}

.App {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(195, 195, 195);
}

.App-link {
  color: #61dafb;
}

/* .title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
  font-size: 3.5rem; /* 52*/
  /* color: rgb(255, 255, 255);
  z-index: 1;
  transition: font-size color 0.5s ease;
} */ 

.apply-button {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  padding: 10px 20px; /* Add some padding */
  color: white; /* Adjust color as needed */
  font-family:"IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 400;
  font-size: 3.5rem; /* 48*/
  z-index: 1;
  text-decoration: none; /* Remove underline by default */
  cursor: pointer;
  transition: all 0.5s ease; /* Smooth transition */
}

.apply-button:hover {
  text-decoration: underline; /* Underline on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allows clicks to pass through */
  backdrop-filter: blur(0px); /* No blur by default */
  transition: backdrop-filter 0.2s ease;
}

.overlay.blur {
  backdrop-filter: blur(30px) brightness(50%);
}

.title-container:hover ~ .overlay {
  backdrop-filter: blur(30px) brightness(50%); /* Apply blur when title is hovered */
}
.apply-button:hover ~ .overlay {
  backdrop-filter: blur(30px) brightness(50%); /* Apply blur when title is hovered */
}

.title-container:hover {
  font-size: 3.5rem;
  color: rgb(255, 255, 255);
}


.title-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  font-family: "IBM Plex Mono", monospace, "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 3.5rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.5s ease; /* Smooth transition */
  max-width: calc(100% - 40px); /* Considering margins */
  max-height: calc(100% - 30%); /* Considering the space for the "Apply" button */
  overflow: auto; /* Make it scrollable */
  z-index: 1;
  padding-bottom: 100px;
}

.info-text {
  font-size: 1rem; /* Adjust for the information text */
  white-space: pre-wrap; /* Preserve formatting */
  transition: opacity 0.5s ease; /* Smooth transition for text appearance */
  padding-right: 10px;
  margin: 20px;
  max-height: 70vh; /* Adjust based on your layout to prevent overlap */
  margin-right: 20px; /* Ensure some space on the right */
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: 768px) {
  .title-container, .title-container:hover{
    font-size: 2.5rem; /* Smaller font size on smaller screens */
  }

  .info-text {
    font-size: 0.7rem;
  }
  
  .apply-button {
    font-size: 2.5rem; /* Smaller "Apply" button text on smaller screens */
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
